import React from 'react'

import { Helmet } from 'react-helmet'

import './wiftrump.css'

const Wiftrump = (props) => {
  return (
    <div className="wiftrump-container">
      <Helmet>
        <title>wiftrump</title>
        <meta property="og:title" content="wiftrump" />
      </Helmet>
      <div className="wiftrump-container1">
        <img
          alt="image"
          src="/external/wetewtqweftewgweget11249-08hp-1800w.png"
          className="wiftrump-image"
        />
        <div className="wiftrump-container2">
          <div className="wiftrump-container3">
            <a
              href="https://t.me/wiftrump"
              target="_blank"
              rel="noreferrer noopener"
              className="wiftrump-link"
            >
              <img
                alt="image"
                src="/external/image224-x9k-200h.png"
                className="wiftrump-image1"
              />
            </a>
          </div>
          <div className="wiftrump-container4">
            <a
              href="https://dexscreener.com/solana/4kX3VFcg3WDREAgEqnz1fTkLCUY1Zi8rNvk3udMBC41Y"
              target="_blank"
              rel="noreferrer noopener"
              className="wiftrump-link1"
            >
              <img
                alt="image"
                src="/unnamed-200h.png"
                className="wiftrump-image2"
              />
            </a>
          </div>
          <div className="wiftrump-container5">
            <a
              href="https://birdeye.so/token/4kX3VFcg3WDREAgEqnz1fTkLCUY1Zi8rNvk3udMBC41Y?chain=solana"
              target="_blank"
              rel="noreferrer noopener"
              className="wiftrump-link2"
            >
              <img
                alt="image"
                src="/external/image1fmxum9hyl7xiqrqihrew222-wg2-200h.png"
                className="wiftrump-image3"
              />
            </a>
          </div>
          <div className="wiftrump-container6">
            <a
              href="https://twitter.com/wiftumpcoin"
              target="_blank"
              rel="noreferrer noopener"
              className="wiftrump-link3"
            >
              <img
                alt="image"
                src="/external/image223-s79-200h.png"
                className="wiftrump-image4"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="wiftrump-container7">
        <img
          alt="image"
          src="/photo_2024-02-16%2010.09.53-1500w.jpeg"
          className="wiftrump-image5"
        />
        <div className="wiftrump-container8">
          <span className="wiftrump-dsfsfsdfs">
            <span>cA:  </span>
            <span>4kX3VFcg3WDREAgEqnz1fTkLCUY1Zi8rNvk3udMBC41Y</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Wiftrump
